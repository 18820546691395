(function () {
  'use strict';

  angular
    .module('neo.home.statistics.neoindi')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.statistics.neoindi', {
        url: '/neoindi',
        templateUrl: 'home/statistics/neoindi/neoindi.tpl.html',
        controller: 'NeoindiCtrl',
        controllerAs: 'vm'
        
      });
  }
}());
